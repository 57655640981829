<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Evaluation Criteria</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                      <div class="mb-4">
                         <!-- advance serach -->
                         <div class="d-flex">
                            <div class="d-flex flex-column me-2">
                            
                                <label class="col-form-label">Criteria</label>
                                <div style="width: 180px">
                                    <v-select label="name" v-model="filter_criteria" :options="criteria" :clearable="false"
                                    :selectable="(options) =>  filter_criteria.id != options.id "
                                    @option:selected="FilterData(filter_criteria,status)">
                                    </v-select>
                                </div>
                            </div>
                            <div class="d-flex flex-column">
                                <label class="col-form-label">Status</label>
                                <div style="width: 170px">
                                    <v-select label="name" v-model="status" :options="arr_status" :clearable="false"
                                        :selectable="(options) => status.name != options.name "
                                        @option:selected="FilterData(filter_criteria, status)">
                                    </v-select>
                                </div>
                            </div>
                            <div class="d-flex mt-4">
                                <!-- <div class="col-form-label d-none">hfhd</div> -->
                                <div @click="refreshData()" class="icon-css">
                                    <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                                </div>
                            </div> 
                        </div>
                            <!--end advance serach -->

                        <div class="d-flex justify-content-end">
                            <router-link :to="{ name: 'trainer-evaluation-criteria-create' }">
                                <button v-show="!loading" type="button"
                                    class="btn btn-sm btn-blue waves-effect waves-light"
                                    v-if="hasPermissions(['trainer-evaluation-criteria-create'])">
                                    <i class="mdi mdi-plus-circle"></i> Add Criteria
                                </button>
                            </router-link>
                        </div>
                      </div>  
                        <place-holder v-if="loading"></place-holder>
                        <div class="table-responsive">
                            <table class="table table-striped dt-responsive w-100 mb-3"
                                id="evaluation-criteria-list-datatable" v-show="!loading">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Criteria</th>
                                        <th>Lowest Score</th>
                                        <th>Highest Score</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in criteria" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.lowest_score }}</td>
                                        <td>{{ item.highest_score }}</td>
                                        <td>
                                            <badgeSuccess v-if="item.status == 1" :name="'Active'" />
                                            <badgeDanger v-else :name="'Inactive'" />
                                        </td>
                                        <td>
                                            <span>
                                                <router-link class="action-icon" :to="{
                                                    name: 'trainer-evaluation-criteria-update',
                                                    params: { id: item.id },
                                                }">
                                                    <i class="mdi mdi-square-edit-outline"></i>
                                                </router-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import badgeDanger from "../../../components/shared/badgeDanger.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
    components: {
        badgeSuccess,
        badgeDanger,
        vSelect
    },
    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            criteria: [],
            filter_criteria : "",
            arr_status : [ {name: "Active"}, {name: "Inactive"} ],
            status : "",
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
        };
    },
    methods: {
        async getAllCriteria() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v2/training-evaluation-criteria`)
                .then((response) => {
                    this.criteria = response.data.data;
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                });
            $("#evaluation-criteria-list-datatable").DataTable().draw();
        },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#evaluation-criteria-list-datatable").DataTable().draw();
        },
        refreshData() {
            this.clearFilter();
            this.criteria = [];
            this.status = "";
            this.filter_criteria = "";
            this.getAllCriteria();
        },
        FilterData(filter_criteria, status) {
            this.clearFilter();
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                const check_filter_criteria = filter_criteria?.name == data[1] ? true : false;
                const check_status = status?.name == data[4] ? true : false;

                if((check_filter_criteria || !filter_criteria.name) && (check_status || !status.name)){
                    return true;
                }
                return false;
            });
            $("#evaluation-criteria-list-datatable").DataTable().draw();
        }
    },
    created() {
        this.clearFilter();
        this.getAllCriteria();
    },
};
</script>
  
<style>
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}
</style>